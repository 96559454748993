import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Button, colors, LoadingSpinner, Tooltip } from 'shared-library';
import { StudyContext, BookingContext } from 'packages/booking-contexts';
import { fetchAppsResource } from 'packages/apis';
import { useToasts } from 'react-toast-notifications';
import { Column, Row } from '../Layout';
import { PaymentContext } from '../PaymentContext';

const DivAroundButton = styled.div`
  width: fit-content;
`;

const giftCertificateIntegratedStatuses = {
  Redeemed: 'Redeemed - The voucher has already been fully used.',
  Void: 'Void - The voucher was cancelled and the customer refunded if applicable.',
  Chargeback:
    'A chargeback against the order occurred. A chargeback can be won in the future in which case the code will return to valid, assuming it has not expired. If the chargeback is lost then it remains in this status forever.',
  'Not Yet Valid': 'The voucher will become valid to use in the future, check the validFromDate',
  Expired: 'Expired - The voucher has passed its expiryDate and graceExpiryDate',
  location_not_allowed: 'Location not allowed',
  no_matching_code: 'Not Found - No matching code was found.',
  unauthorized: 'Unauthorized',
  forbidden: 'Forbidden',
  Valid: 'Valid - The voucher is inside its validity dates and has balance that can be used.',
  'Grace Period': 'The expiryDate is in the past but the graceExpiryDate is still in the future.',
  'Partially Redeemed': 'Some but not all of the voucher balance has been used.',
};

const PaymentButton = ({ transaction, openAlert, onChange }) => {
  const { addToast } = useToasts();
  const { paymentType, load, recordPayment } = useContext(PaymentContext);
  const { studyId } = useContext(StudyContext);
  const { isTemporaryHold } = useContext(BookingContext);
  let disablePaymentButton = !transaction.amount || transaction.amount === '0.00';

  if (paymentType === 'giftCertificateIntegrated') {
    disablePaymentButton = !transaction?.referenceID;
  }

  const [saving, setSaving] = useState(false);

  const showNotification = (value, status) => {
    if (!value) return;
    const content = giftCertificateIntegratedStatuses[value] ?? value;
    const appearance = status;
    addToast(content, { appearance });
  };

  const validatePaymentInformation = () => {
    if (!studyId || studyId === 'None') {
      return {
        title: 'Please complete location, time, and patient details before processing payment',
      };
    }
    const title = 'Please complete all payment details';
    let text;
    if (!transaction.referenceID) {
      if (paymentType === 'clinic') {
        if (transaction.paymentMethod === 'cheque') {
          text = 'Cheque Number';
        } else if (
          transaction.paymentMethod === 'credit' ||
          transaction.paymentMethod === 'debit'
        ) {
          text = 'Confirmation Code';
        }
      } else if (paymentType === 'cheque') {
        text = 'Cheque Number';
      } else if (paymentType === 'gift certificate') {
        text = 'Certificate Number';
      } else if (['externalCredit', 'externalLender'].includes(paymentType)) {
        text = 'Confirmation Code';
      }
    }
    if (text) {
      return {
        title,
        text,
      };
    }
    return {};
  };

  const loadAndConvertTemporaryHold = async () => {
    await load();

    if (isTemporaryHold) {
      await fetchAppsResource('/calendar/convert-temporary-hold', 'POST', { studyId });
    }
  };

  const savePayment = async () => {
    try {
      const { title, text } = validatePaymentInformation();
      if (text) {
        return openAlert(title, text);
      }
      setSaving(true);
      const { amount } = transaction;
      const response = await recordPayment({ paymentType, amount, details: transaction }, onChange);
      if (
        response?.error &&
        response?.code !== 200 &&
        paymentType === 'giftCertificateIntegrated'
      ) {
        showNotification(response?.error, 'error');
      } else if (
        response?.voucher &&
        response?.code === 200 &&
        paymentType === 'giftCertificateIntegrated'
      ) {
        showNotification(response?.voucher?.status, 'success');
        await loadAndConvertTemporaryHold();
      } else if (response?.transaction) {
        await loadAndConvertTemporaryHold();
      } else if (typeof response === 'string') {
        openAlert('Payment Error', response || 'Something went wrong. Please try again later.');
      } else {
        openAlert('Payment Error', 'Something went wrong. Please try again later.');
      }
      return false;
    } catch (error) {
      openAlert('Payment Error', 'Something went wrong. Please try again later.');
      throw new Error(error.message);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Row>
      <Column>
        {!transaction.amount && paymentType !== 'giftCertificateIntegrated' && (
          <Tooltip id="paymentAmount" text="Please enter a payment amount." />
        )}
        <DivAroundButton data-tip="" data-for="paymentAmount">
          <Button
            disabled={saving || disablePaymentButton}
            size="medium"
            onClick={savePayment}
            variant="primary"
          >
            {saving ? <LoadingSpinner color={colors.white} size="small" /> : 'Save Payment'}
          </Button>
        </DivAroundButton>
      </Column>
    </Row>
  );
};

PaymentButton.propTypes = {
  onChange: PropTypes.func.isRequired,
  openAlert: PropTypes.func.isRequired,
  transaction: PropTypes.shape({
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    paymentMethod: PropTypes.string,
    referenceID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
};

export default PaymentButton;
